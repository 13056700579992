import React, { useEffect } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Apoios from "root/components/Apoios";
import ThankYouWindow from "root/sections/ThankYouWindow";
import { useTranslation } from "react-i18next";

const ThankYou = ({ location }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  const color = location?.state?.color ?? "";

  useEffect(() => {
    if (!color) {
      navigate("/en");
    }

    i18n.changeLanguage("en");
  }, []);

  return (
    <div>
      <SEO title={t("pages.thankYou.label")} />
      <Layout>
        <ThankYouWindow color={color} />
        <Apoios />
      </Layout>
    </div>
  );
};

ThankYou.propTypes = {
  location: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default ThankYou;
